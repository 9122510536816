// import { isUnauthorized } from "helpers/errors";
import axios, { AxiosError } from "axios";

const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API
    ? process.env.REACT_APP_AUTH_API
    : "https://digitaly-auth.digitaly.tech/api",
});

const axiosWebsite = axios.create({
  baseURL: process.env.REACT_APP_WEBSITE_API
    ? process.env.REACT_APP_WEBSITE_API
    : "https://digitaly-api.digitaly.tech/api",
});

const axiosEmail = axios.create({
  baseURL: process.env.REACT_APP_MAIL_API
    ? process.env.REACT_APP_MAIL_API
    : "https://digitaly-mail.digitaly.tech/api",
});

const axiosCep = axios.create({
  baseURL: process.env.REACT_APP_CEP_API
    ? process.env.REACT_APP_CEP_API
    : "https://viacep.com.br/ws/",
});

const axiosGeo = axios.create({
  baseURL: process.env.REACT_APP_GEO_API
    ? process.env.REACT_APP_GEO_API
    : "https://nominatim.openstreetmap.org/search?",
});

export function isAxiosError(candidate: any): candidate is AxiosError {
  return candidate.isAxiosError === true;
}

export { axiosAuth, axiosWebsite, axiosEmail, axiosCep, axiosGeo };
