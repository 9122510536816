import { devices } from "helpers/devices";
import styled from "styled-components";
import WatsonIcon from "../../assets/watson.svg";
import RobotIcon from "../../assets/robot.svg";
import DevIcon from "../../assets/dev.svg";
import BigDataIcon from "../../assets/big-data.svg";
import DigitalServicesIcon from "../../assets/digital-service.svg";
import SecurityIcon from "../../assets/security.svg";

const FindOutStyled = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 100vh;
  width: 100vw;
  min-height: 750px;
  max-height: 750px;
  max-width: 100%;

  @media ${devices.phoneOnly} {
    min-height: 1550px;
    max-height: 1550px;
  }

  @media ${devices.tabletPortrait} {
    min-height: 1000px;
    max-height: 1000px;
  }

  @media ${devices.tabletLandscape} {
    min-height: 1100px;
    max-height: 1100px;
  }

  @media ${devices.desktop} {
    min-height: 750px;
    max-height: 750px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }
`;

const SolidLine = styled.div`
  height: 2px;
  width: 50px;
  border-radius: 10px;
  display: block;
  background-color: ${props => props.theme.color.gray4};
  margin-top: 1rem;
`;

const H2 = styled.p`
  color: ${props => props.theme.color.gray4};
  font-size: 1.2rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    font-size: 1rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1rem;
  }

  @media ${devices.desktop} {
    font-size: 1.2rem;
  }
`;

const H1 = styled.p`
  color: ${props => props.theme.color.primary_main};
  font-size: 2.5rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    font-size: 2rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 2rem;
  }

  @media ${devices.desktop} {
    font-size: 2.5rem;
  }
`;

const ServicesContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
`;

const ServicesItem = styled.div`
  width: 100%;
  max-width: 500px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  & div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const ServiceNumber = styled.p`
  height: 70px;
  width: 70px;
  background: ${props => props.theme.color.primary_main};
  font-family: "IBM Plex Sans", Sans-serif;
  font-weight: bold;
  text-align: right;
  color: ${props => props.theme.color.white1};
  padding: 10px;
  display: flex;
  font-size: 2.2rem;
  line-height: 1.3;
  box-sizing: border-box;
  border-radius: 50%;
  flex-shrink: 0;

  @media ${devices.phoneOnly} {
    font-size: 1.8rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.8rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1.8rem;
  }

  @media ${devices.desktop} {
    font-size: 2.2rem;
  }
`;

const ServiceTitle = styled.p`
  padding: 0 1rem;
  color: ${props => props.theme.color.primary_main};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.3;
  box-sizing: border-box;
  height: 70px;
  display: flex;
  align-items: center;

  @media ${devices.phoneOnly} {
    font-size: 1.3rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.3rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1.3rem;
  }

  @media ${devices.desktop} {
    font-size: 1.8rem;
  }
`;

const ServiceText = styled.p`
  padding: 0.5rem 1rem;
  color: ${props => props.theme.color.gray4};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 1.3;
  box-sizing: border-box;
  letter-spacing: -0.6px;

  @media ${devices.phoneOnly} {
    font-size: 1rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1rem;
  }

  @media ${devices.desktop} {
    font-size: 1.2rem;
  }
`;

const FindOut = (): JSX.Element => {
  const services = [
    {
      title: "Watson Platform",
      description:
        "Somos parceiros oficiais, implementadores  da plataforma de serviços cognitivos Watson da IBM.",
      icon: WatsonIcon,
    },
    {
      title: "Robotizção DIGITALY",
      description:
        "Somos pioneiros em Robotic Process Automation no Brasil, vamos além da robotização de processos, implantamos um modelo de governança para a força de trabalho digital unido com o cognitivo.",
      icon: RobotIcon,
    },
    {
      title: "Desenvolvimento de Softwares",
      description:
        "Desenvolvimento  e integração de soluções complexas, com tercerização do suporte, manutenção e melhorias no portfólio de aplicações. Modernização do legado.",
      icon: DevIcon,
    },
    {
      title: "Analytics Big Data",
      description:
        "O que a sua empresa está fazendo com o potencial não explorado dos dados que não param de aumentar?",
      icon: BigDataIcon,
    },
    {
      title: "Digital Service ",
      description:
        "Buscamos unir negocios com conhecimento, tecnlogia e cultura.",
      icon: DigitalServicesIcon,
    },
    {
      title: "Security Services",
      description:
        "Utilização de tecnlogias e métodos focando em manter a segurança do usuario e do sistema.",
      icon: SecurityIcon,
    },
  ];

  return (
    <FindOutStyled id="7">
      <Container>
        <SolidLine />
        <H2>DESCUBRA</H2>
        <H1>Nossos Serviços</H1>
        <ServicesContainer>
          {services.map((service, keyService) => (
            <ServicesItem key={`service${keyService}`}>
              <ServiceNumber>
                <img src={service.icon} alt={service.title} />
              </ServiceNumber>
              <div>
                <ServiceTitle>{service.title}</ServiceTitle>
                <ServiceText>{service.description}</ServiceText>
              </div>
            </ServicesItem>
          ))}
        </ServicesContainer>
      </Container>
    </FindOutStyled>
  );
};

export default FindOut;
