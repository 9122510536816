import styled from "styled-components";
import { ReactComponent as Facebook } from "assets/facebook.svg";
import { ReactComponent as LinkedIn } from "assets/linkedIn.svg";
import { ReactComponent as Instagram } from "assets/instagram.svg";
import { ReactComponent as YouTube } from "assets/youtube.svg";
import { devices } from "helpers/devices";

const FooterStyled = styled.section`
  background-color: ${props => props.theme.color.primary_main};
  height: 100vh;
  width: 100vw;
  min-height: 200px;
  max-height: 200px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 0.5rem ${props => props.theme.margins.from_sides};
  }

  @media ${devices.tabletLandscape} {
    padding: 1rem ${props => props.theme.margins.from_sides};
  }
`;

const ItemsContainer = styled.div`
  height: 80px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 0.5rem ${props => props.theme.margins.from_sides};
  }

  @media ${devices.tabletLandscape} {
    padding: 1rem ${props => props.theme.margins.from_sides};
  }
`;

const FacebookIcon = styled(Facebook)`
  width: 25px;
  height: 25px;
  margin: 0.3rem;
  background: ${props => props.theme.color.white1};
  border: solid 1px ${props => props.theme.color.white1};
  border-radius: 50%;
  cursor: pointer;

  & path {
    fill: ${props => props.theme.color.primary_light};
  }
  &:hover {
    & path {
      fill: #1778f2;
    }
  }
`;

const InstagramIcon = styled(Instagram)`
  width: 25px;
  height: 25px;
  margin: 0.3rem;
  background: ${props => props.theme.color.white1};
  border: solid 1px ${props => props.theme.color.white1};
  border-radius: 50%;
  cursor: pointer;

  & path {
    fill: ${props => props.theme.color.primary_light};
  }
  &:hover {
    & path {
      fill: #9049c4;
    }
  }
`;

const YouTubeIcon = styled(YouTube)`
  width: 25px;
  height: 25px;
  margin: 0.3rem;
  background: ${props => props.theme.color.white1};
  border: solid 1px ${props => props.theme.color.white1};
  border-radius: 50%;
  cursor: pointer;

  & path {
    fill: ${props => props.theme.color.primary_light};
  }
  &:hover {
    & path {
      fill: #e72932;
    }
  }
`;

const LinkedInIcon = styled(LinkedIn)`
  width: 25px;
  height: 25px;
  margin: 0.3rem;
  background: ${props => props.theme.color.white1};
  border: solid 1px ${props => props.theme.color.white1};
  border-radius: 50%;
  cursor: pointer;

  & path {
    fill: ${props => props.theme.color.primary_light};
  }
  &:hover {
    & path {
      fill: #0077b7;
    }
  }
`;

const H2 = styled.p`
  color: ${props => props.theme.color.white1};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 2rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin-bottom: 1rem;
  text-align: start;

  @media ${devices.phoneOnly} {
    font-size: 1.5rem;
    text-align: center;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.5rem;
    text-align: center;
  }

  @media ${devices.tabletLandscape} {
    font-size: 2rem;
    text-align: start;
  }
`;

const P = styled.p`
  color: ${props => props.theme.color.white1};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1rem;
  line-height: 1;
  box-sizing: border-box;

  @media ${devices.phoneOnly} {
    font-size: 0.9rem;
    text-align: center;
  }

  @media ${devices.tabletPortrait} {
    font-size: 0.9rem;
    text-align: center;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1rem;
    text-align: start;
  }
`;

const Footer = (): JSX.Element => {
  return (
    <FooterStyled id="14">
      <ItemsContainer>
        <a
          title="Facebook"
          href="https://www.facebook.com/Digitaly-Technology-114181877119261"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          title="Instagram"
          href="https://www.instagram.com/digitalytechnology/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
        <a
          title="YouTube"
          href="https://www.youtube.com/channel/UCmL8b4rg9juzCOLIjehLwKA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon />
        </a>
        <a
          title="LinkedIn"
          href="https://www.linkedin.com/company/digitalytech/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </a>
      </ItemsContainer>
      <Container>
        <H2>Think different. Think digital. Go Digitaly!</H2>
        <P>
          Copyright © 2021 Digitaly Technology | Todos os Direitos Reservados
        </P>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
