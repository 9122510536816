import GlobalLoading from "components/generic/GlobalLoading";
import GlobalMessage from "components/generic/GlobalMessage";
import SuccessMessage from "components/generic/SuccessMessage";
import Content from "pages/Content";
import Landing from "pages/Landing";
import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { getHomePosts } from "services/ghost";

function App() {
  useEffect(() => {
    getHomePosts();
  }, []);

  return (
    <>
      <SuccessMessage />
      <GlobalMessage />
      {/* <GlobalLoading /> */}
      <Router>
        <Switch>
          <Route path="/digitalynews/:id/:created_date/:title">
            <Content />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
