import styled from "styled-components";
import Alyson from "assets/alyson-santos.jpeg";
import Pieter from "assets/pieter-lekkerkerk.png";
import Marcos from "assets/marcos-parra.png";
import ScrollUpwardsAnimation from "components/generic/ScrollUpwardsAnimation";
import { useInView } from "react-intersection-observer";
import { devices } from "helpers/devices";

const TeamStyled = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 100vh;
  width: 100vw;
  min-height: 650px;
  max-height: 650px;
  max-width: 100%;

  @media ${devices.phoneOnly} {
    min-height: 1000px;
    max-height: 1000px;
  }

  @media ${devices.tabletPortrait} {
    min-height: 1000px;
    max-height: 1000px;
  }

  @media ${devices.tabletLandscape} {
    min-height: 500px;
    max-height: 500px;
  }

  @media ${devices.desktop} {
    min-height: 650px;
    max-height: 650px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margins.from_sides};
  }
`;

const H1 = styled.h1`
  color: ${props => props.theme.color.primary_main};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 2.5rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    font-size: 2rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 2rem;
  }

  @media ${devices.desktop} {
    font-size: 2.5rem;
  }
`;

const P = styled.p`
  color: ${props => props.theme.color.gray4};
  font-size: 1.2rem;
  line-height: 1;
  box-sizing: border-box;

  @media ${devices.phoneOnly} {
    font-size: 1rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1rem;
  }

  @media ${devices.desktop} {
    font-size: 1.2rem;
  }
`;

const SolidLine = styled.div`
  height: 5px;
  width: 39px;
  border-radius: 10px;
  display: block;
  background-color: ${props => props.theme.color.primary_main};
  margin-top: 1rem;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: block;
  }
`;

const ExecutiveContent = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
  animation: ${ScrollUpwardsAnimation} ease-out 1 1s both
    ${props => (props.isVisible ? "running" : "paused")};

  @media ${devices.phoneOnly} {
    flex-wrap: wrap;
  }

  @media ${devices.tabletPortrait} {
    flex-wrap: wrap;
  }

  @media ${devices.tabletLandscape} {
    flex-wrap: nowrap;
  }
`;

const Executive = styled.div`
  width: 380px;
  height: 360px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  & h3 {
    color: ${props => props.theme.color.primary_main};
    font-size: 1.2rem;
    font-weight: bold;
  }

  & p {
    text-align: center;
    color: ${props => props.theme.color.gray4};
    font-size: 0.9rem;
  }

  @media ${devices.phoneOnly} {
    width: 250px;
    height: 250px;

    & h3 {
      font-size: 1rem;
    }

    & p {
      font-size: 0.8rem;
    }
  }

  @media ${devices.tabletPortrait} {
    width: 250px;
    height: 250px;

    & h3 {
      font-size: 1rem;
    }

    & p {
      font-size: 0.8rem;
    }
  }

  @media ${devices.tabletLandscape} {
    width: 250px;
    height: 250px;

    & h3 {
      font-size: 1rem;
    }

    & p {
      font-size: 0.8rem;
    }
  }

  @media ${devices.desktop} {
    width: 380px;
    height: 360px;

    & h3 {
      font-size: 1.2rem;
    }

    & p {
      font-size: 0.9rem;
    }
  }
`;

const ImgContainer = styled.div`
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${devices.phoneOnly} {
    height: 100px;
    width: 100px;
  }

  @media ${devices.tabletPortrait} {
    height: 100px;
    width: 100px;
  }

  @media ${devices.tabletLandscape} {
    height: 100px;
    width: 100px;
  }

  @media ${devices.desktop} {
    height: 200px;
    width: 200px;
  }
`;

const Img = styled.img`
  border-radius: 50%;
  height: 100%;
  width: auto;
`;

const Team = (): JSX.Element => {
  const [animationRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <TeamStyled id="4">
      <Container>
        <H1>Nossos Executivos</H1>
        <P>Fundada e gerida por um grupo experiente.</P>
        <SolidLine />
        <ExecutiveContent ref={animationRef} isVisible={inView}>
          <Executive>
            <ImgContainer>
              <Img src={Alyson} alt="Alyson Santos" />
            </ImgContainer>
            <h3>Alyson Santos</h3>
            <p>
              DIGITALY Director, Alyson é ex-Executivo da IBM (GBS – Global
              Business Services / Consultoria da IBM), onde atuou por aprox 14
              anos. Formação em Computação e Pós Graduação pela USP/Poli.
            </p>
          </Executive>
          <Executive>
            <ImgContainer>
              <Img src={Pieter} alt="Pieter Lekkerkerk" />
            </ImgContainer>
            <h3>Pieter Lekkerkerk</h3>
            <p>
              DIGITALY Strategy Consulting Executive. Pieter é Ex-Executivo da
              IBM e McKinsey & Co. Formação Financial Econometrics pela Erasmus
              University Rotterdam e MBA em Banking Strategy pelo INSEAD.
            </p>
          </Executive>
          <Executive>
            <ImgContainer>
              <Img src={Marcos} alt="Marcos Parra" />
            </ImgContainer>
            <h3>Marcos Parra</h3>
            <p>
              Head Digitaly Security, Parra é ex-Executivo da IBM Security
              Services onde atuou por 20 anos. Formação em Matemática,
              Pós-Graduado em Segurança da Informação e MBA em Master Coach.
            </p>
          </Executive>
        </ExecutiveContent>
      </Container>
    </TeamStyled>
  );
};

export default Team;
