import styled, { keyframes } from "styled-components";
import Logo1 from "assets/Logo 4.png";
import Logo2 from "assets/Logo 6.png";
import { ReactComponent as Arrow } from "assets/Arrow.svg";
import { useEffect, useState } from "react";
import Scroll from "react-scroll";
import { devices } from "helpers/devices";
import OpenMenu from "./SubComponents/OpenMenu";
import GenericButton from "components/generic/GenericButton";
import { ReactComponent as IconMenu } from "assets/IconMenu.svg";
const scroller = Scroll.scroller;

const Scalable = keyframes`
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

const HeaderStyle = styled.header<{ scrolling: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 10000;
  -webkit-box-shadow: ${props =>
    props.scrolling ? "0 1px 5px rgba(0, 0, 0, 0.25)" : "none"};
  -moz-box-shadow: ${props =>
    props.scrolling ? "0 1px 5px rgba(0, 0, 0, 0.25)" : "none"};
  box-shadow: ${props =>
    props.scrolling ? "0 1px 5px rgba(0, 0, 0, 0.25)" : "none"};
  transition: all 0.2s ease-in-out;
  height: 80px;
  background-color: ${props =>
    props.scrolling ? "rgba(255, 255, 255, 0.95)" : "transparent"};
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 0 ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 0 ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 0 ${props => props.theme.margins.from_sides};
  }
`;

const ImgContainer = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Logo = styled.img`
  height: 100%;
  width: auto;
`;

const Nav = styled.nav`
  margin: 0 4rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: flex;
    margin: 0 1rem;
  }

  @media ${devices.desktop} {
    margin: 0 3rem;
  }

  @media ${devices.bigDesktop} {
    margin: 0 4rem;
  }
`;

const Ul = styled.ul<{ scrolling: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;

  & a {
    text-decoration: none;
  }

  & li {
    cursor: pointer;
    padding: 13px 20px;
    transform: scale(0.9);

    &:hover {
      transform: scale(1);
      border-bottom: 2px solid
        ${props =>
          props.scrolling ? props.theme.color.gray3 : props.theme.color.white1};
      border-radius: 10px;
    }
  }
`;

const A = styled.a<{ scrolling: boolean }>`
  white-space: nowrap;
  color: ${props =>
    props.scrolling ? props.theme.color.gray1 : props.theme.color.white1};
`;

const Button = styled.button`
  animation: ${Scalable} infinite 1s;
  background: ${props => props.theme.color.white1};
  color: ${props => props.theme.color.gray1};
  padding: 1rem;
  font-weight: 600;
  white-space: nowrap;
  border-radius: 100px;
  width: 200px;
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  & svg {
    width: 15px;
    height: 15px;
  }

  @media ${devices.tabletLandscape} {
    width: 150px;
  }

  @media ${devices.desktop} {
    width: 200px;
  }
`;

const ButtonMobile = styled.button`
  padding: 0.5rem;
  margin: 0.1rem;
  border: none;
  background: transparent;
  text-align: start;
  border-bottom: 0.1rem solid ${props => props.theme.color.primary_main};
  width: 100%;
  color: ${props => props.theme.color.text2};

  & a {
    text-decoration: none;
  }

  & p:hover {
    text-decoration: underline;
  }
`;

const LinkOne = styled.p<{ Color?: boolean; textDecoration?: boolean }>`
  height: 100%;
  color: ${props =>
    props.Color ? props.theme.color.text2 : props.theme.color.primary_dark};
  text-decoration: ${props => (props.textDecoration ? "underline" : "none")};
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
`;

const MenuMobile = styled(GenericButton)<{ isDeny?: boolean }>`
  width: auto;
  height: 40px;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  font-size: ${props => props.theme.font.normal};
  background: ${props =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.theme.color.primary_dark};

  &:hover {
    background: ${props =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.theme.color.primary_dark};
  }

  & svg {
    width: 25px;
    height: 25px;
    fill: ${props => props.theme.color.white1};
  }

  @media ${devices.phoneOnly} {
    display: flex;
  }

  @media ${devices.tabletPortrait} {
    display: flex;
  }

  @media ${devices.tabletLandscape} {
    display: none;
  }
`;

const Header = (): JSX.Element => {
  const [scrolling, setScrolling] = useState(false);
  const [showUtilities, setShowUtilities] = useState<boolean>(false);
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = (event: any) => {
    setScrollTop(event.target.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (scrollTop > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  }, [scrollTop]);

  return (
    <>
      <HeaderStyle scrolling={scrolling} id="1">
        <Container>
          <ImgContainer
            onClick={() =>
              scroller.scrollTo("2", {
                duration: 1500,
                delay: 100,
                smooth: true,
              })
            }
          >
            <Logo
              src={scrolling ? Logo2 : Logo1}
              alt="Logotipo da Digitaly"
            ></Logo>
          </ImgContainer>
          <Nav>
            <Ul scrolling={scrolling}>
              <li
                onClick={() =>
                  scroller.scrollTo("3", {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: -60,
                  })
                }
              >
                <A scrolling={scrolling}>Sobre</A>
              </li>
              <li
                onClick={() =>
                  scroller.scrollTo("4", {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: -60,
                  })
                }
              >
                <A scrolling={scrolling}>Time</A>
              </li>
              <li
                onClick={() =>
                  scroller.scrollTo("5", {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: -60,
                  })
                }
              >
                <A scrolling={scrolling}>Serviços</A>
              </li>
              <li
                onClick={() =>
                  scroller.scrollTo("8", {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: -60,
                  })
                }
              >
                <A scrolling={scrolling}>Cases</A>
              </li>
              <li
                onClick={() =>
                  scroller.scrollTo("11", {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: -60,
                  })
                }
              >
                <A scrolling={scrolling}>Blog</A>
              </li>
              <li
                onClick={() =>
                  scroller.scrollTo("12", {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: -60,
                  })
                }
              >
                <A scrolling={scrolling}>FAQ</A>
              </li>
              <a
                title="Fale Conosco"
                href="https://api.whatsapp.com/send?phone=5511991810079"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>
                  Fale Conosco <Arrow />
                </Button>
              </a>
            </Ul>
          </Nav>
          <MenuMobile onClick={() => setShowUtilities(!showUtilities)}>
            <IconMenu />
          </MenuMobile>
        </Container>
      </HeaderStyle>

      <OpenMenu
        showHeader={showUtilities}
        handleShowHeader={() => setShowUtilities(!showUtilities)}
      >
        <>
          <h3>Navegação</h3>
          <ButtonMobile>
            <LinkOne
              onClick={() => {
                scroller.scrollTo("2", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                });
                setShowUtilities(!showUtilities);
              }}
            >
              Início
            </LinkOne>
          </ButtonMobile>
          <ButtonMobile>
            <LinkOne
              onClick={() => {
                scroller.scrollTo("3", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                  offset: -60,
                });
                setShowUtilities(!showUtilities);
              }}
            >
              Sobre
            </LinkOne>
          </ButtonMobile>
          <ButtonMobile>
            <LinkOne
              onClick={() => {
                scroller.scrollTo("4", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                  offset: -60,
                });
                setShowUtilities(!showUtilities);
              }}
            >
              Time
            </LinkOne>
          </ButtonMobile>
          <ButtonMobile>
            <LinkOne
              onClick={() => {
                scroller.scrollTo("5", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                  offset: -60,
                });
                setShowUtilities(!showUtilities);
              }}
            >
              Serviços
            </LinkOne>
          </ButtonMobile>
          <ButtonMobile>
            <LinkOne
              onClick={() => {
                scroller.scrollTo("8", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                  offset: -60,
                });
                setShowUtilities(!showUtilities);
              }}
            >
              Cases
            </LinkOne>
          </ButtonMobile>
          <ButtonMobile>
            <LinkOne
              onClick={() => {
                scroller.scrollTo("11", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                  offset: -60,
                });
                setShowUtilities(!showUtilities);
              }}
            >
              Blog
            </LinkOne>
          </ButtonMobile>
          <ButtonMobile>
            <LinkOne
              onClick={() => {
                scroller.scrollTo("12", {
                  duration: 1500,
                  delay: 100,
                  smooth: true,
                  offset: -60,
                });
                setShowUtilities(!showUtilities);
              }}
            >
              FAQ
            </LinkOne>
          </ButtonMobile>
          <ButtonMobile>
            <a
              title="Fale Conosco"
              href="https://api.whatsapp.com/send?phone=5511991810079"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOne
                onClick={() => {
                  setShowUtilities(!showUtilities);
                }}
              >
                Fale Conosco
              </LinkOne>
            </a>
          </ButtonMobile>
        </>
      </OpenMenu>
    </>
  );
};

export default Header;
