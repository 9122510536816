import { GlobalState, AllowedActions } from "./types";

// -- State Inicial --
const initialState: GlobalState = {
  globalMessage: undefined,
  globalLoading: false,
  successMessage: undefined,
  userId: undefined,
};

// -- atribuindo switch --
export function globalReducer(state = initialState, action: AllowedActions) {
  switch (action.type) {
    case "GLOBAL_MESSAGE/SET":
      return {
        ...state,
        globalMessage: action.payload,
      };
    case "GLOBAL_MESSAGE/CLEAR":
      return {
        ...state,
        globalMessage: undefined,
      };
    case "GLOBAL_LOADING/TOGGLE":
      return {
        ...state,
        globalLoading: !state.globalLoading,
      };
    case "SUCCESS_MESSAGE/SET":
      return {
        ...state,
        successMessage: action.payload,
      };
    case "SUCCESS_MESSAGE/CLEAR":
      return {
        ...state,
        successMessage: undefined,
      };
    case "USER_ID/SET":
      return {
        ...state,
        userId: action.payload,
      };
    case "USER_ID/CLEAR":
      return {
        ...state,
        userId: undefined,
      };
    default:
      return state;
  }
}
