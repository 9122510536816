import styled from "styled-components";
import FariaLima from "assets/fariaLima.png";
import Anhangabau from "assets/anhangabau.png";
import SaoCarlos from "assets/saoCarlos.png";
import MiamiFlorida from "assets/miamiFlorida.png";
import { devices } from "helpers/devices";

const LocalizationStyled = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 100vh;
  width: 100vw;
  min-height: 400px;
  max-height: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media ${devices.phoneOnly} {
    min-height: 1100px;
    max-height: 1100px;
  }

  @media ${devices.tabletPortrait} {
    min-height: 1100px;
    max-height: 1100px;
  }

  @media ${devices.tabletLandscape} {
    min-height: 600px;
    max-height: 600px;
  }

  @media ${devices.desktop} {
    min-height: 400px;
    max-height: 400px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }
`;

const DivTitle = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const H2 = styled.p`
  color: ${props => props.theme.color.gray1};
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;
`;

const DivItems = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0.5rem;
`;

const Item = styled.div`
  width: 260px;
  height: 240px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  & h3 {
    color: ${props => props.theme.color.gray1};
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1;
  }

  & p {
    font-weight: 300;
    text-align: left;
    white-space: normal;
    color: ${props => props.theme.color.gray4};
    font-size: 0.9rem;
  }
`;

const ImgContainer = styled.div`
  height: 130px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  border-radius: 50%;
  height: 100%;
  width: auto;
`;

const SolidLineBlue = styled.div`
  height: 5px;
  width: 50px;
  border-radius: 10px;
  display: block;
  background-color: ${props => props.theme.color.primary_main};
`;

const Localization = (): JSX.Element => {
  return (
    <LocalizationStyled id="13">
      <Container>
        <DivTitle>
          <H2>Localização</H2>
        </DivTitle>
        <SolidLineBlue />
        <DivItems>
          <Item>
            <ImgContainer>
              <Img src={FariaLima} alt="Faria Lima" />
            </ImgContainer>
            <h3>Faria Lima</h3>
            <p>
              Av. Brigadeiro Faria Lima, 2012 – Cj. 112 Jardim Paulista • São
              Paulo - SP
            </p>
          </Item>
          <Item>
            <ImgContainer>
              <Img src={Anhangabau} alt="Anhangabaú" />
            </ImgContainer>
            <h3>Anhangabaú</h3>
            <p>Rua Coronel Xavier de Toledo 105, 7º andar - salas A e B</p>
          </Item>
          <Item>
            <ImgContainer>
              <Img src={SaoCarlos} alt="São Carlos" />
            </ImgContainer>
            <h3>São Carlos</h3>
            <p>Av. Trab. São Carlense, 400</p>
          </Item>
          <Item>
            <ImgContainer>
              <Img src={MiamiFlorida} alt="Miami, Florida" />
            </ImgContainer>
            <h3>Miami, Florida</h3>
            <p>801 Brickell Avenue Miami, FL 33131</p>
          </Item>
        </DivItems>
      </Container>
    </LocalizationStyled>
  );
};

export default Localization;
