import styled from "styled-components";
import IbmImg from "assets/IBM Business Partner.png";
import { devices } from "helpers/devices";

const AboutStyle = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 100vh;
  min-height: 500px;
  max-height: 500px;
  width: 100vw;
  max-width: 100%;

  @media ${devices.phoneOnly} {
    min-height: 650px;
    max-height: 650px;
  }

  @media ${devices.tabletPortrait} {
    min-height: 750px;
    max-height: 750px;
  }

  @media ${devices.tabletLandscape} {
    min-height: 500px;
    max-height: 500px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 0.3rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margins.from_sides};
  }
`;

const SolidLine = styled.div`
  height: 5px;
  width: 39px;
  border-radius: 10px;
  display: block;
  background-color: ${props => props.theme.color.primary_main};
  margin-top: 1rem;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: block;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${devices.phoneOnly} {
    flex-direction: column;
  }

  @media ${devices.tabletPortrait} {
    flex-direction: column;
  }

  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }
`;

const DivImg = styled.div`
  max-width: 500px;
  height: 100%;
  padding: 4rem 0;

  @media ${devices.phoneOnly} {
    max-width: 300px;
    padding: 1rem 0;
    height: auto;
  }

  @media ${devices.tabletPortrait} {
    height: auto;
    max-width: 400px;
    padding: 2rem 0;
  }

  @media ${devices.tabletLandscape} {
    height: 100%;
  }

  @media ${devices.desktop} {
    max-width: 500px;
  }
`;

const Img = styled.img`
  height: auto;
  width: 100%;
`;

const DivText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem 1rem;

  @media ${devices.phoneOnly} {
    padding: 1rem;
  }

  @media ${devices.tabletPortrait} {
    padding: 2rem 1rem;
  }

  @media ${devices.tabletLandscape} {
    padding: 4rem 1rem;
  }
`;

const H2 = styled.h2`
  color: ${props => props.theme.color.primary_main};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1.75rem;
  line-height: 1;
  box-sizing: border-box;

  @media ${devices.phoneOnly} {
    font-size: 1.5rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.5rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1.5rem;
  }

  @media ${devices.desktop} {
    font-size: 1.75rem;
  }
`;

const H1 = styled.h1`
  color: ${props => props.theme.color.primary_main};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    font-size: 2.2rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2.2rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 2.2rem;
  }

  @media ${devices.desktop} {
    font-size: 2.8rem;
  }
`;

const P = styled.p`
  color: ${props => props.theme.color.gray4};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1.2rem;
  line-height: 1;
  box-sizing: border-box;

  @media ${devices.phoneOnly} {
    font-size: 1rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1rem;
  }

  @media ${devices.desktop} {
    font-size: 1.2rem;
  }
`;

const About = (): JSX.Element => {
  return (
    <AboutStyle id="3">
      <Container>
        <SolidLine />
        <Content>
          <DivImg>
            <Img src={IbmImg} alt="IBM Business Partner" />
          </DivImg>
          <DivText>
            <H2>Sobre a Digitaly</H2>
            <H1>Origem proveniente da IBM, McKinsey e USP</H1>
            <P>
              Uma empresa fundada em 2005. Tem em seu DNA o modelo de inovação e
              qualidade originado da IBM. Fundada por ex-executivos IBM e
              McKinsey. Somos uma empresa experiente como uma grande
              consultoria! Ágil e DIGITAL como uma startup! Criativa e Inovadora
              com um centro de pesquisa em São Carlos-USP!
            </P>
          </DivText>
        </Content>
      </Container>
    </AboutStyle>
  );
};

export default About;
