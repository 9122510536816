import {
  GlobalLoadingActionTypes,
  GlobalMessageActionTypes,
  SuccessMessageActionTypes,
  UserIdActionTypes,
} from "./types";
import { GlobalMessage } from "interfaces/GlobalMessage";

// -- Declaration String --

// Global message
export const SET_GLOBAL_MESSAGE = "GLOBAL_MESSAGE/SET";
export const CLEAR_GLOBAL_MESSAGE = "GLOBAL_MESSAGE/CLEAR";

//Global Loading
export const TOGGLE_GLOBAL_LOADING = "GLOBAL_LOADING/TOGGLE";

//Success Message
export const SET_SUCCESS_MESSAGE = "SUCCESS_MESSAGE/SET";
export const CLEAR_SUCCESS_MESSAGE = "SUCCESS_MESSAGE/CLEAR";

// USER ID
export const SET_USER_ID = "USER_ID/SET";
export const CLEAR_USER_ID = "USER_ID/CLEAR";

// USER PROFILE
export const SET_USER = "USER/SET";
export const CLEAR_USER = "USER/CLEAR";

//-- Functions --

//Global Message
export function setGlobalMessage(
  globalMessage: GlobalMessage
): GlobalMessageActionTypes {
  return {
    type: SET_GLOBAL_MESSAGE,
    payload: globalMessage,
  };
}

export function clearGlobalMessage(): GlobalMessageActionTypes {
  return {
    type: CLEAR_GLOBAL_MESSAGE,
  };
}

//Global Loading
export function toggleGlobalLoading(): GlobalLoadingActionTypes {
  return {
    type: TOGGLE_GLOBAL_LOADING,
  };
}

//Success Message
export function setSuccessMessage(message: string): SuccessMessageActionTypes {
  return {
    type: SET_SUCCESS_MESSAGE,
    payload: message,
  };
}

export function clearSuccessMessage(): SuccessMessageActionTypes {
  return {
    type: CLEAR_SUCCESS_MESSAGE,
  };
}

//User ID
export function setUserId(id: string): UserIdActionTypes {
  return {
    type: SET_USER_ID,
    payload: id,
  };
}

export function clearUserId(): UserIdActionTypes {
  return {
    type: CLEAR_USER_ID,
  };
}
