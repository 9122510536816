import styled, { keyframes } from "styled-components";
import { ReactComponent as Logistics } from "assets/logistics.svg";
import { ReactComponent as Industry } from "assets/industry.svg";
import { ReactComponent as Financial } from "assets/financial.svg";
import { devices } from "helpers/devices";

const HoverUp = keyframes`
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, -5%);
  }
`;

const HoverDown = keyframes`
  0% {
    transform: translate(0%, -5%);
  }
  100% {
    transform: translate(0%, 0%);
  }
`;

const SolutionStyled = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 100vh;
  width: 100vw;
  min-height: 600px;
  max-height: 600px;
  max-width: 100%;

  @media ${devices.phoneOnly} {
    min-height: 1100px;
    max-height: 1100px;
  }

  @media ${devices.tabletPortrait} {
    min-height: 1100px;
    max-height: 1100px;
  }

  @media ${devices.tabletLandscape} {
    min-height: 1100px;
    max-height: 1100px;
  }

  @media ${devices.desktop} {
    min-height: 600px;
    max-height: 600px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }
`;

const SolidLine = styled.div`
  height: 2px;
  width: 50px;
  border-radius: 10px;
  display: block;
  background-color: ${props => props.theme.color.gray4};
  margin-top: 1rem;
`;

const SolutionsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media ${devices.phoneOnly} {
    flex-direction: column;
  }

  @media ${devices.tabletPortrait} {
    flex-direction: column;
  }

  @media ${devices.tabletLandscape} {
    flex-direction: column;
  }

  @media ${devices.desktop} {
    flex-direction: row;
  }
`;

const Card = styled.div`
  width: 300px;
  height: 450px;
  padding: 3rem 2rem;
  box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 10%);
  border-style: solid;
  border-width: 0px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${HoverDown} 1s 1 forwards;

  &:hover {
    animation: ${HoverUp} 1s 1 forwards;
  }

  & svg {
    width: 50px;
    height: 50px;
    margin: 0.5rem;

    & path {
      fill: ${props => props.theme.color.primary_main};
    }
  }

  @media ${devices.phoneOnly} {
    width: 250px;
    height: 300px;
    padding: 1rem 0rem;
  }

  @media ${devices.tabletPortrait} {
    width: 250px;
    height: 300px;
    padding: 1rem 0rem;
  }

  @media ${devices.tabletLandscape} {
    width: 250px;
    height: 300px;
    padding: 1rem 0rem;
  }

  @media ${devices.desktop} {
    width: 300px;
    height: 450px;
    padding: 3rem 2rem;
  }
`;

const H2 = styled.p`
  color: ${props => props.theme.color.primary_main};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1.5rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    font-size: 1.1rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.1rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1.1rem;
  }

  @media ${devices.desktop} {
    font-size: 1.5rem;
  }
`;

const P = styled.p`
  padding: 0.5rem 1rem;
  color: ${props => props.theme.color.gray4};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  box-sizing: border-box;
  letter-spacing: -0.6px;
  text-align: center;

  @media ${devices.phoneOnly} {
    font-size: 0.8rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 0.8rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 0.8rem;
  }

  @media ${devices.desktop} {
    font-size: 1rem;
  }
`;

const Solutions = (): JSX.Element => {
  return (
    <SolutionStyled id="9">
      <Container>
        <SolidLine />
        <SolutionsContainer>
          <Card>
            <Logistics />
            <H2>Setor Logistico</H2>
            <P>
              Com a solução da Digitaly, a operadora logística obteve aumento de
              performance de 4.600% neste processo, que antes levava mais de
              nove horas e agora é realizado em 13 minutos, sem dependência
              humana.
            </P>
          </Card>
          <Card>
            <Financial />
            <H2>Setor Financeiro</H2>
            <P>
              Com a solução Digitaly, empresa do setor financeiro obteve
              resultados na automatização de processos de pagamento bancários,
              emissão de relatórios e uma melhor comunicação com equipe interna.
            </P>
          </Card>
          <Card>
            <Industry />
            <H2>Setor Industrial</H2>
            <P>
              Com a solução Digitaly, empresa do setor industrial que antes
              tinha processos repetitivos, foram implantados 4 robôs para
              automatizar esses processos. Também foi implantado na Aline uma
              abertura de chamados para equipe interna
            </P>
          </Card>
        </SolutionsContainer>
      </Container>
    </SolutionStyled>
  );
};

export default Solutions;
