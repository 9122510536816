import About from "components/about/About";
import Aline from "components/aline/Aline";
import Blog from "components/blog/Blog";
import NextBlog from "components/blog/NextBlog";
import Faq from "components/faq/Faq";
import FindOut from "components/findOut/FindOut";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import Hero from "components/hero/Hero";
import Localization from "components/localization/Localization";
import NotLeftOut from "components/notLeftOut/NotLeftOut";
import Numbers from "components/numbers/Numbers";
import Services from "components/services/Services";
import Solutions from "components/solutions/Solutions";
import Team from "components/team/Team";
// import styled from "styled-components";

const Landing = (): JSX.Element => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Team />
      <FindOut />
      <Services />
      <Aline />
      <NotLeftOut />
      <Solutions />
      <Numbers />
      <Blog />
      {/* <NextBlog /> */}
      <Faq />
      <Localization />
      <Footer />
    </>
  );
};

export default Landing;
