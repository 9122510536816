import styled from "styled-components";
import ContentRender from "components/blog/ContentBlog/Content";

const Container = styled.section`
  position: relative;
`;

const Content = (): JSX.Element => {
  return (
    <Container>
      <ContentRender />
    </Container>
  );
};

export default Content;
