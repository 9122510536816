import ScrollUpwardsAnimation from "components/generic/ScrollUpwardsAnimation";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { devices } from "helpers/devices";

const NotLeftOutStyled = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 100vh;
  width: 100vw;
  min-height: 200px;
  max-height: 200px;
  max-width: 100%;

  @media ${devices.phoneOnly} {
    min-height: 180px;
    max-height: 180px;
  }

  @media ${devices.tabletPortrait} {
    min-height: 160px;
    max-height: 160px;
  }

  @media ${devices.tabletLandscape} {
    min-height: 170px;
    max-height: 170px;
  }

  @media ${devices.desktop} {
    min-height: 200px;
    max-height: 200px;
  }
`;

const Container = styled.div<{ isVisible: boolean }>`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${ScrollUpwardsAnimation} ease-out 1 1s both
    ${props => (props.isVisible ? "running" : "paused")};

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }
`;

const H2 = styled.p`
  color: ${props => props.theme.color.gray4};
  font-size: 1.2rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    font-size: 0.9rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1rem;
  }

  @media ${devices.desktop} {
    font-size: 1.2rem;
  }
`;

const H1 = styled.p`
  color: ${props => props.theme.color.primary_main};
  font-size: 2.5rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;
  text-align: center;

  @media ${devices.phoneOnly} {
    font-size: 1.5rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 2rem;
  }

  @media ${devices.desktop} {
    font-size: 2.5rem;
  }
`;

const SolidLine = styled.div`
  height: 2px;
  width: 50px;
  border-radius: 10px;
  display: block;
  background-color: ${props => props.theme.color.gray4};
  margin-top: 1rem;
`;

const NotLeftOut = (): JSX.Element => {
  const [animationRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  return (
    <NotLeftOutStyled id="8">
      <Container ref={animationRef} isVisible={inView}>
        <SolidLine />
        <H2>NÃO FIQUE DE FORA</H2>
        <H1>Cases da DIGITALY</H1>
      </Container>
    </NotLeftOutStyled>
  );
};

export default NotLeftOut;
