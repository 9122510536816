import styled from "styled-components";
import Linhas from "assets/Linhas verticais.png";
import Linha from "assets/linha.png";
import Phones from "assets/Iphones.png";
import { devices } from "helpers/devices";

const ServicesStyled = styled.section`
  background-color: ${props => props.theme.color.primary_main};
  height: 100vh;
  width: 100vw;
  min-height: 900px;
  max-height: 900px;
  max-width: 100%;

  @media ${devices.phoneOnly} {
    min-height: 400px;
    max-height: 400px;
  }

  @media ${devices.tabletPortrait} {
    min-height: 400px;
    max-height: 400px;
  }

  @media ${devices.tabletLandscape} {
    min-height: 900px;
    max-height: 900px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 260px 1fr 60px;

  @media ${devices.phoneOnly} {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  @media ${devices.tabletLandscape} {
    display: grid;
  }
`;

const DualTop = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  height: 100%;
  width: 100%;
  padding: 0.1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media ${devices.phoneOnly} {
    height: auto;
  }

  @media ${devices.tabletPortrait} {
    height: auto;
  }

  @media ${devices.tabletLandscape} {
    height: 100%;
  }
`;

const DualBottom = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: flex;
  }
`;

const OneMidLeft = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media ${devices.phoneOnly} {
    height: auto;
    align-items: center;
  }

  @media ${devices.tabletPortrait} {
    height: auto;
  }

  @media ${devices.tabletLandscape} {
    height: 100%;
  }
`;

const OneMidRight = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: flex;
  }
`;

const ImgContainer = styled.div`
  height: 240px;
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: flex;
    height: 140px;
    width: 260px;
  }

  @media ${devices.desktop} {
    height: 240px;
    width: 360px;
  }
`;

const Img = styled.img`
  height: 100%;
  width: auto;
`;

const ImgBottom = styled.img`
  height: auto;
  width: 100%;
`;

const H1 = styled.p`
  height: 200px;
  width: 300px;
  color: ${props => props.theme.color.white1};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 2.8rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;
  text-align: center;

  @media ${devices.phoneOnly} {
    height: auto;
    font-size: 2rem;
  }

  @media ${devices.tabletPortrait} {
    height: auto;
    font-size: 2.8rem;
  }

  @media ${devices.tabletLandscape} {
    height: 200px;
  }
`;

const H2 = styled.p`
  color: ${props => props.theme.color.white1};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 2.6rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;
  text-align: start;

  @media ${devices.phoneOnly} {
    text-align: center;
    font-size: 1.8rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2.6rem;
  }
`;

const P = styled.p`
  color: ${props => props.theme.color.white1};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1.2rem;
  line-height: 1;
  box-sizing: border-box;

  @media ${devices.phoneOnly} {
    text-align: center;
    font-size: 1rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.2rem;
  }
`;

const Services = (): JSX.Element => {
  return (
    <ServicesStyled id="5">
      <Container>
        <DualTop>
          <ImgContainer>
            <Img src={Linhas} alt="Linhas verticais" />
          </ImgContainer>
          <H1>
            Conheça a <br />
            <b>ALINE</b>
            <br />
          </H1>
          <ImgContainer>
            <Img src={Linhas} alt="Linhas verticais" />
          </ImgContainer>
        </DualTop>
        <OneMidLeft>
          <H2>
            Automação Cognitiva
            <br />
            <b>DIGITALY</b>
          </H2>
          <P>
            A Aline veio para ajudar na transformação digital do seu negócio.
            Uma tecnologia DIGITALY para automatizar seus processos, facilitar
            na comunicação da sua empresa e integrar todos os seus sistemas em
            um só lugar.
          </P>
        </OneMidLeft>
        <OneMidRight>
          <ImgBottom src={Phones} alt="Imagem de Iphones" />
        </OneMidRight>
        <DualBottom>
          <ImgBottom src={Linha} alt="Linha Horizontal" />
        </DualBottom>
      </Container>
    </ServicesStyled>
  );
};

export default Services;
