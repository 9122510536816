import styled from "styled-components";
import BgBlog from "assets/bgBlog.jpg";
import IaImg from "assets/iaBg.jpg";
import Carousel from "react-elastic-carousel";
import { ReactComponent as Arrow } from "assets/Arrow.svg";
import { devices } from "helpers/devices";
import { useEffect, useState } from "react";
import { CardContent } from "interfaces/Content";
import { useDispatch } from "react-redux";
import { toggleGlobalLoading } from "redux/actions";
import { axiosWebsite } from "helpers/axiosInstances";

const BlogStyled = styled.section`
  background-image: url("${BgBlog}");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  min-height: 800px;
  max-height: 800px;
  max-width: 100%;
`;

const WhiteColor = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }
`;

const UpContainer = styled.div`
  background-color: ${props => props.theme.color.white1};
  padding: 1rem ${props => props.theme.margins.from_sides};
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media ${devices.phoneOnly} {
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }
`;

const H1 = styled.p`
  color: ${props => props.theme.color.primary_main};
  font-size: 2.5rem;
  line-height: 1.3;
  box-sizing: border-box;
  text-align: center;
  margin-top: 0.5rem;

  @media ${devices.phoneOnly} {
    font-size: 1.8rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 2.5rem;
  }
`;

const SolidLine = styled.div`
  height: 2px;
  width: 50px;
  border-radius: 10px;
  display: block;
  background-color: ${props => props.theme.color.gray4};
  margin-top: 1rem;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: block;
  }
`;

const SolidLineBlue = styled.div`
  height: 5px;
  width: 50px;
  border-radius: 10px;
  display: block;
  background-color: ${props => props.theme.color.primary_main};
  margin-top: 1rem;
`;

const Card = styled.div`
  width: 250px;
  height: 400px;
  background-color: ${props => props.theme.color.white1};
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 10%);
  }
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BaseImg = styled.img`
  object-fit: fill;
  max-width: auto;
  max-height: 100%;
`;

const InsertImg = styled.div`
  width: 100%;
  height: 170px;
  margin-bottom: 0.5rem;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const H2 = styled.p`
  color: ${props => props.theme.color.primary_main};
  font-size: 1.3rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 0.5rem 0;
  max-width: 220px;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
`;

const P = styled.p`
  color: ${props => props.theme.color.gray4};
  font-size: 0.9rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 0.5rem 0;
  width: 220px;
  height: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
`;

const A = styled.a`
  color: ${props => props.theme.color.primary_main};
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.3;
  box-sizing: border-box;
  cursor: pointer;
  transform: scale(0.9);

  &:hover {
    transform: scale(1);
  }

  & svg {
    width: 15px;
    height: 15px;
    margin: 0 0.5rem;

    & path {
      fill: ${props => props.theme.color.primary_main};
    }
  }
`;

const Blog = (): JSX.Element => {
  const [content, setContent] = useState<CardContent[]>([]);
  const dispatch = useDispatch();

  const fetchContent = async () => {
    try {
      dispatch(toggleGlobalLoading());

      const { data } = await axiosWebsite.get(`/content-site`);
      setContent(data.data);
    } catch (error) {
      alert(error);
    } finally {
      dispatch(toggleGlobalLoading());
    }
  };

  useEffect(() => {
    fetchContent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BlogStyled id="11">
      <WhiteColor>
        <UpContainer>
          <SolidLine />
          <H1>
            Confira nosso <b>BLOG</b>
          </H1>
        </UpContainer>
        <Container>
          <SolidLineBlue />
          <CardContainer>
            <Carousel
              isRTL={false}
              itemsToShow={4}
              breakPoints={[
                {
                  width: 599,
                  itemsToShow: 1,
                },
                {
                  width: 600,
                  itemsToShow: 2,
                },
                {
                  width: 800,
                  itemsToShow: 3,
                },
              ]}
            >
              {content.map(content => (
                <Card key={content.id}>
                  <InsertImg>
                    <BaseImg
                      src={
                        content.image_card === "" ||
                        content.image_card === null ||
                        content.image_card === undefined
                          ? IaImg
                          : "data:image/jpeg;base64," + content.image_card
                      }
                      alt="IMG Teste"
                      draggable={false}
                    />
                  </InsertImg>
                  <H2>{content.title}</H2>
                  <P>{content.subtitle}</P>
                  <A
                    href={
                      content.type === "EXTERNAL"
                        ? content.url
                        : `/digitalynews/${content.id}/${content.created_date
                            .substr(0, 10)
                            .split("-")
                            .join("/")}/${content.title.split(" ").join("-")}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ler Mais <Arrow />
                  </A>
                </Card>
              ))}
            </Carousel>
          </CardContainer>
        </Container>
      </WhiteColor>
    </BlogStyled>
  );
};

export default Blog;
