import styled from "styled-components";
import AlineImg from "assets/Aline.png";
import { devices } from "helpers/devices";

const AlineStyle = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 100vh;
  width: 100vw;
  min-height: 800px;
  max-height: 800px;
  max-width: 100%;

  @media ${devices.phoneOnly} {
    min-height: 1100px;
    max-height: 1100px;
  }

  @media ${devices.tabletPortrait} {
    min-height: 1100px;
    max-height: 1100px;
  }

  @media ${devices.tabletLandscape} {
    min-height: 800px;
    max-height: 800px;
  }
`;

const Container = styled.div`
  background-color: ${props => props.theme.color.primary_main};
  clip-path: ellipse(90% 100% at 50% 0%);
  height: 100%;
  width: 100%;

  @media ${devices.phoneOnly} {
    clip-path: none;
  }

  @media ${devices.tabletPortrait} {
    clip-path: none;
  }

  @media ${devices.tabletLandscape} {
    clip-path: ellipse(90% 100% at 50% 0%);
  }
`;

const Template = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: grid;
  grid-template-columns: 280px 1fr 280px;
  grid-template-rows: 1fr 60px;

  @media ${devices.phoneOnly} {
    display: flex;
    flex-direction: column;
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    display: flex;
    flex-direction: column;
  }

  @media ${devices.tabletLandscape} {
    display: grid;
    grid-template-columns: 230px 1fr 230px;
  }

  @media ${devices.desktop} {
    grid-template-columns: 280px 1fr 280px;
  }
`;

const MidLeft = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  height: 100%;
  width: 100%;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media ${devices.phoneOnly} {
    height: auto;
  }

  @media ${devices.tabletPortrait} {
    height: auto;
  }

  @media ${devices.tabletLandscape} {
    height: 100%;
  }
`;

const MidRight = styled.div`
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  height: 100%;
  width: 100%;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media ${devices.phoneOnly} {
    height: auto;
  }

  @media ${devices.tabletPortrait} {
    height: auto;
  }

  @media ${devices.tabletLandscape} {
    height: 100%;
  }
`;

const MidPane = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${devices.phoneOnly} {
    height: auto;
  }

  @media ${devices.tabletPortrait} {
    height: auto;
  }

  @media ${devices.tabletLandscape} {
    height: 100%;
  }
`;

const H2 = styled.p<{ alignLeft?: boolean }>`
  color: ${props => props.theme.color.white1};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1.6rem;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;
  text-align: ${props => (props.alignLeft ? "start" : "end")};

  @media ${devices.phoneOnly} {
    font-size: 1.2rem;
    text-align: center;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.2rem;
    text-align: center;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1.2rem;
    text-align: ${props => (props.alignLeft ? "start" : "end")};
  }

  @media ${devices.desktop} {
    font-size: 1.6rem;
  }
`;

const P = styled.p<{ alignLeft?: boolean }>`
  color: ${props => props.theme.color.white1};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1rem;
  line-height: 1;
  box-sizing: border-box;
  text-align: ${props => (props.alignLeft ? "start" : "end")};

  @media ${devices.phoneOnly} {
    font-size: 0.9rem;
    text-align: center;
  }

  @media ${devices.tabletPortrait} {
    font-size: 0.9rem;
    text-align: center;
  }

  @media ${devices.tabletLandscape} {
    font-size: 0.9rem;
    text-align: ${props => (props.alignLeft ? "start" : "end")};
  }

  @media ${devices.desktop} {
    font-size: 1rem;
  }
`;

const Img = styled.img`
  height: auto;
  width: 100%;
  max-width: 500px;

  @media ${devices.tabletLandscape} {
    width: 100%;
  }
`;

const SolidLine = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  align-self: center;
  justify-self: center;
  height: 5px;
  width: 39px;
  border-radius: 10px;
  display: block;
  background-color: ${props => props.theme.color.white1};
  margin-top: 1rem;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: block;
  }
`;

const Aline = (): JSX.Element => {
  return (
    <AlineStyle id="6">
      <Container>
        <Template>
          <MidPane>
            <Img src={AlineImg} alt="Imagem da Assistente Virtual Aline" />
          </MidPane>
          <MidLeft>
            <div>
              <H2>Integrações</H2>
              <P>
                Integra-se a diferentes plataformas internas e externas sem a
                necessidade de codificação ou ajustes de sistemas, cooperando
                com o seu ecossistema empresarial.
              </P>
            </div>
            <div>
              <H2>Agilidade</H2>
              <P>
                Com os algoritmos automatizados, acumular e processar
                informações dispersas requer quase nenhum esforço humano. Ou
                seja, a tecnologia é aplicada para entregar soluçõs ágeis e
                inteligentes para o negócio.
              </P>
            </div>
          </MidLeft>
          <MidRight>
            <div>
              <H2 alignLeft>Redução de Custos</H2>
              <P alignLeft>
                As aplicações que utilizam IA são capazes de reduzir erros e
                aumentar a produtividade. Por isso, empresas que adotam a
                automação Cognitiva DIGITALY conseguem eliminar facilmente
                diversos custos operacionais.
              </P>
            </div>
            <div>
              <H2 alignLeft>Inovação</H2>
              <P alignLeft>
                Por meio de algoritmos avançados, é possivel detectar e, ainda
                mais impressionante, reagir a acontecimentos em tempo real.
                Assim sua equipe tem mais dominio sobre suas aplicações e
                necessidades.
              </P>
            </div>
          </MidRight>
          <SolidLine />
        </Template>
      </Container>
    </AlineStyle>
  );
};

export default Aline;
