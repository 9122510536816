import { keyframes } from "styled-components";

const ScrollUpwardsAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(80px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
    `;

export default ScrollUpwardsAnimation;
