import styled, { keyframes } from "styled-components";
import { ReactComponent as Arrow } from "assets/downArrow.svg";
import { useState } from "react";
import { devices } from "helpers/devices";

const FadeToView = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

const FaqStyled = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 100vh;
  width: 100vw;
  min-height: 600px;
  max-height: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media ${devices.phoneOnly} {
    min-height: 650px;
    max-height: 650px;
  }

  @media ${devices.tabletPortrait} {
    min-height: 650px;
    max-height: 650px;
  }

  @media ${devices.tabletLandscape} {
    min-height: 620px;
    max-height: 620px;
  }

  @media ${devices.desktop} {
    min-height: 600px;
    max-height: 600px;
  }
`;

const DivBlue = styled.div`
  background-color: ${props => props.theme.color.primary_main};
  width: 100%;
  height: 100%;
  max-height: 100px;
  display: flex;
  flex-direction: column;

  & div {
    justify-content: center;
  }

  @media ${devices.phoneOnly} {
    max-height: 80px;
  }

  @media ${devices.tabletPortrait} {
    max-height: 80px;
  }

  @media ${devices.tabletLandscape} {
    max-height: 100px;
  }
`;

const DivWhite = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media ${devices.phoneOnly} {
    padding: 0.1rem ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 0.1rem ${props => props.theme.margins.from_sides};
  }

  @media ${devices.tabletLandscape} {
    padding: 1rem ${props => props.theme.margins.from_sides};
  }
`;

const H2 = styled.p`
  color: ${props => props.theme.color.white1};
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    font-size: 2rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 3rem;
  }
`;

const FaqContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;

  @media ${devices.phoneOnly} {
    padding: 0rem;
  }
`;

const FaqItem = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 80px;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.7rem;
  border-bottom: solid 1px
    ${props =>
      props.isOpen ? props.theme.color.white2 : props.theme.color.white4};
  cursor: pointer;
`;

const FaqTitle = styled.p`
  color: ${props => props.theme.color.gray2};
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4rem;
  box-sizing: border-box;

  @media ${devices.phoneOnly} {
    font-size: 1rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1.1rem;
  }
`;

const NewArrow = styled(Arrow)<{ isOpen: boolean }>`
  width: 19px;
  height: 19px;
  transform: ${props => (props.isOpen ? "rotateZ(0.5turn)" : "rotateZ(0)")};
`;

const FaqResult = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  display: ${props => (props.isOpen ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  -webkit-animation: ${FadeToView} 1s 1;
  -moz-animation: ${FadeToView} 1s 1;
  animation: ${FadeToView} 1s 1;

  @media ${devices.phoneOnly} {
    padding: 1rem;
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem;
  }

  @media ${devices.tabletLandscape} {
    padding: 1.5rem;
  }
`;

const FaqText = styled.p`
  color: ${props => props.theme.color.gray3};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  box-sizing: border-box;
  font-family: "Hind", Sans-serif;

  @media ${devices.phoneOnly} {
    font-size: 0.9rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 0.9rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1rem;
  }
`;

const Faq = (): JSX.Element => {
  const [option1, setOption1] = useState<boolean>(true);
  const [option2, setOption2] = useState<boolean>(false);
  const [option3, setOption3] = useState<boolean>(false);

  return (
    <FaqStyled id="12">
      <DivBlue>
        <Container>
          <H2>FAQ</H2>
        </Container>
      </DivBlue>
      <DivWhite>
        <Container>
          <FaqContainer>
            <FaqItem
              isOpen={option1}
              onClick={() => {
                setOption1(!option1);
                setOption2(false);
                setOption3(false);
              }}
            >
              <FaqTitle>A Aline pode me ajudar ?</FaqTitle>
              <NewArrow isOpen={option1} />
            </FaqItem>
            <FaqResult isOpen={option1}>
              <FaqText>
                A Aline, automação cognitiva da digitaly pode ajudar a sua
                empresa a eliminar processos repetitivos e também reduzir custos
                operacionais.
              </FaqText>
            </FaqResult>
            <FaqItem
              isOpen={option2}
              onClick={() => {
                setOption1(false);
                setOption2(!option2);
                setOption3(false);
              }}
            >
              <FaqTitle>O que é Robotização ?</FaqTitle>
              <NewArrow isOpen={option2} />
            </FaqItem>
            <FaqResult isOpen={option2}>
              <FaqText>
                Trata-se de uma aplicação tecnológica que permite a
                automatização de processos com o uso de robôs. Assim, é possível
                automatizar demandas repetitivas, operacionais e/ou de baixa
                importância. Basicamente, o que os robôs fazem é “imitar” a
                atuação de um ser humano para executar atividades como cliques e
                comandos em um computador.
              </FaqText>
            </FaqResult>
            <FaqItem
              isOpen={option3}
              onClick={() => {
                setOption1(false);
                setOption2(false);
                setOption3(!option3);
              }}
            >
              <FaqTitle>A Aline pode ser integrada ao WhatsApp ?</FaqTitle>
              <NewArrow isOpen={option3} />
            </FaqItem>
            <FaqResult isOpen={option3}>
              <FaqText>
                Quando integrada ao WhatsApp da empresa, a Aline pode
                automatizar o atendimento ao cliente, tornando-o mais rápido e
                eficiente.
              </FaqText>
            </FaqResult>
          </FaqContainer>
        </Container>
      </DivWhite>
    </FaqStyled>
  );
};

export default Faq;
