import { devices } from "helpers/devices";
import styled from "styled-components";
import CountUp, { useCountUp } from "react-countup";
import { useInView } from "react-intersection-observer";
import { useEffect, useRef } from "react";

const NumberStyled = styled.section`
  background-color: ${props => props.theme.color.primary_main};
  height: 100vh;
  width: 100vw;
  min-height: 250px;
  max-height: 250px;
  max-width: 100%;

  @media ${devices.phoneOnly} {
    min-height: 350px;
    max-height: 350px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem ${props => props.theme.margins.from_sides};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${devices.phoneOnly} {
    flex-direction: column;
    padding: 0.5rem ${props => props.theme.margins.from_sides_mobile};
  }
`;

const H2 = styled.p`
  color: ${props => props.theme.color.white1};
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.3;
  box-sizing: border-box;
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    font-size: 2rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 3rem;
  }
`;

const H4 = styled.p`
  padding: 0.5rem 1rem;
  color: ${props => props.theme.color.white1};
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.3;
  box-sizing: border-box;
  letter-spacing: -0.6px;
  text-align: center;

  @media ${devices.phoneOnly} {
    font-size: 1rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1.5rem;
  }
`;

const CountRender: React.FC<{ end: number }> = ({ end }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { start } = useCountUp({
    start: 0,
    end,
    startOnMount: false,
    ref: divRef,
  });

  useEffect(() => {
    const element = divRef.current!;
    const observer = new IntersectionObserver(entries => {
      const entry = entries?.[0];
      if (!entry) return;
      if (entry.isIntersecting) {
        start();
      }
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);

  return (
    <>
      <span ref={divRef} />
    </>
  );
};

const Numbers = (): JSX.Element => {
  return (
    <>
      <NumberStyled id="10">
        <Container>
          <div>
            <H2>
              +<CountRender end={85} />
            </H2>
            <H4>Operações Robotizadas</H4>
          </div>
          <div>
            <H2>
              +<CountRender end={254} />
            </H2>
            <H4>Assistentes de IA implementadas</H4>
          </div>
          <div>
            <H2>
              +<CountRender end={90} />
            </H2>
            <H4>Colaboradores na Digitaly</H4>
          </div>
        </Container>
      </NumberStyled>
    </>
  );
};

export default Numbers;
