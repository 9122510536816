import GhostContentAPI from "@tryghost/content-api";

export const ghostClient = new GhostContentAPI({
  key: "2c0bd4bf989d6b3eb7ab562081",
  url: "http://localhost:2368",
  version: "v3",
});

export const getAllPosts = async () => {
  return ghostClient.posts.browse({
    page: 1,
    limit: 20,
    include: ["tags", "authors"],
    formats: "html",
  });
};

export const getHomePosts = async () => {
  return ghostClient.posts.browse({
    page: 1,
    limit: 100,
    include: ["tags", "authors"],
    formats: "html",
  });
};
