import styled, { keyframes } from "styled-components";
import Background from "assets/Imagem Principal.jpg";
import { ReactComponent as MouseIcon } from "assets/Mouse.svg";
import Scroll from "react-scroll";
import { devices } from "helpers/devices";
const scroller = Scroll.scroller;

const Scalable = keyframes`
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

const HeroStyle = styled.section`
  background-color: ${props => props.theme.color.white1};
  height: 95vh;
  width: 100vw;
  max-width: 100%;
`;

const Container = styled.div`
  background-color: transparent;
  background-image: url("${Background}");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px 0px 150px 0px;
  height: 100%;
  width: 100%;

  @media ${devices.phoneOnly} {
    border-radius: 0px 0px 70px 0px;
    background-position: center left;
  }
`;

const BackgroundOverlay = styled.div`
  background-color: rgba(47, 113, 179, 0.3);
  border-radius: 0px 0px 150px 0px;
  height: 100%;
  width: 100%;
  padding: 0 ${props => props.theme.margins.from_sides};

  @media ${devices.phoneOnly} {
    padding: 0 ${props => props.theme.margins.from_sides_mobile};
    border-radius: 0px 0px 70px 0px;
  }

  @media ${devices.tabletPortrait} {
    padding: 0 ${props => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 0 ${props => props.theme.margins.from_sides};
  }
`;

const TemplateGrid = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 120px;

  @media ${devices.phoneOnly} {
    padding: 0rem;
    display: flex;
  }

  @media ${devices.tabletPortrait} {
    padding: 0rem;
  }

  @media ${devices.tabletLandscape} {
    padding: 1rem;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-column-start: 2;
  grid-row-start: 1;
`;

const H1 = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  font-family: "IBM Plex Sans", Sans-serif;
  color: ${props => props.theme.color.white1};
  line-height: 1;

  @media ${devices.phoneOnly} {
    font-size: 1.8rem;
    text-align: start;
    width: 250px;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 2rem;
  }

  @media ${devices.desktop} {
    font-size: 3rem;
  }
`;

const P = styled.p`
  height: 105px;
  line-height: 2;
  box-sizing: border-box;
  text-align: left;
  color: ${props => props.theme.color.white1};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 1.9rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media ${devices.phoneOnly} {
    font-size: 1.3rem;
    text-align: start;
    width: 250px;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.5rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1.5rem;
  }

  @media ${devices.desktop} {
    font-size: 1.9rem;
  }
`;

const WidgetContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;

  @media ${devices.phoneOnly} {
    display: none;
  }
`;

const SolidLine = styled.div`
  height: 2px;
  width: 400px;
  display: block;
  background-color: ${props => props.theme.color.white4};

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: block;
  }
`;

const ContinueDiv = styled.div`
  height: 100%;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const H2 = styled.h2`
  color: ${props => props.theme.color.white1};
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 0.9rem;
  letter-spacing: 1.3px;
  box-sizing: border-box;

  @media ${devices.phoneOnly} {
    font-size: 0.8rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 0.8rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 0.8rem;
  }

  @media ${devices.desktop} {
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  animation: ${Scalable} infinite 1s;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 70px;
  height: 70px;

  & svg {
    width: 50px;
    height: 50px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
    & path {
      fill: ${props => props.theme.color.gray4};
    }
  }
`;

const Hero = (): JSX.Element => {
  return (
    <HeroStyle id="2">
      <Container>
        <BackgroundOverlay>
          <TemplateGrid>
            <TextContainer>
              <H1>Ágil, criativa e experiente</H1>
              <P>
                Somos uma consultoria em tecnologia especializada em
                transformação digital.
              </P>
            </TextContainer>
            <WidgetContainer>
              <SolidLine />
              <ContinueDiv>
                <H2>Continue</H2>
                <Button
                  onClick={() =>
                    scroller.scrollTo("3", {
                      duration: 1500,
                      delay: 100,
                      smooth: true,
                      offset: -60,
                    })
                  }
                >
                  <MouseIcon />
                </Button>
              </ContinueDiv>
            </WidgetContainer>
          </TemplateGrid>
        </BackgroundOverlay>
      </Container>
    </HeroStyle>
  );
};

export default Hero;
